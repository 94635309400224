if (window.USE_CIS_COOKIE_NOTICE === undefined) {
    window.USE_CIS_COOKIE_NOTICE = true;
}

var AS_STYLEGUIDE = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1440,
        xxxl: 1660
    }
};;/**
 * For setting body classes to make styling easer depending on the resolution.
 */
class MobileHelper {

    static isMobile() {
        if(window.innerWidth <= AS_STYLEGUIDE.breakpoints.lg) {
            return true;
        }
        return false;
    }

    static getCurrentView() {
        var wwidth = window.innerWidth;
        switch (true) {
            case (wwidth <= AS_STYLEGUIDE.breakpoints.sm): return "sm";
            case (wwidth <= AS_STYLEGUIDE.breakpoints.md): return "md";
            case (wwidth <= AS_STYLEGUIDE.breakpoints.lg): return "lg";
            case (wwidth <= AS_STYLEGUIDE.breakpoints.xl): return "xl";
            case (wwidth <= AS_STYLEGUIDE.breakpoints.xxl): return "xxl";
            default: return "xxxl";
        }
    }

    static setBodyClasses() {
        if(this.isMobile()) {
            jQuery('body').removeClass('desktop').addClass('mobile');
        } else {
            jQuery('body').addClass('desktop').removeClass('mobile');
        }

        this.removeBodyClassWildcard();
        jQuery('body').addClass('mh-res-' + this.getCurrentView());
    }

    static removeBodyClassWildcard() {
        jQuery('body').removeClass (function (index, className) {
            return (className.match (/(^|\s)mh-res-\S+/g) || []).join(' ');
        });
    }
};/* Main file for custom JS */
(function($) {
    var date = new Date(Date.now());
    console.log("JS is ready: " + date.toString()  + ", Millis: " + date.getMilliseconds());

    customOnResize();

    $(window).resize(function() {
        customOnResize();
    });

    // all actions to perform on browser window resize */
    function customOnResize() {
        MobileHelper.setBodyClasses();
    }

    // Enable Material Selectors globally
    $(document).ready(function() {
        $('.mdb-select').materialSelect();
    });

    // Enable Tooltips globally
    $('[data-toggle="tooltip"]').tooltip()


    setTimeout(function () {
        let inputs = $('.md-form input:not([type="button"]):not([type="checkbox"]):not([type="radio"]:not([type="file"]):not([type="hidden"]:not([type="submit"]))), .md-textarea');
        $.each( inputs, function( key, value ) {
            if($(this).val()) {
                $(this).next("label").addClass('active');
            }
        });

        // Handle labels for form fields autofilled by the browser.
        $('input:autofill, input:-webkit-autofill').each( function() {
            $(this).siblings('label').addClass('active');
        });

    }, 100);

}) (jQuery);
;(function($) {

    // close mobile menu on window resize
    $( window ).resize(function() {
        if(!MobileHelper.isMobile() && $('body').hasClass('menu-mobile-open')) {
            $('body').removeClass('menu-mobile-open');
        }

    });

    // avoid any conflicts with desktop menu
    if(MobileHelper.isMobile()) {

        // Open or close the menu
        $(document).on('click','.navbar-toggler[data-target="#header_nav_mobile"]',function(e) {
            e.preventDefault();
            e.stopPropagation();
            if($('body').hasClass('menu-mobile-open')) {
                $('body').removeClass('menu-mobile-open');
            } else {
                $('body').addClass('menu-mobile-open');
            }
            return false;
        });

        // Open or close the first level submenu
        $(document).on('click','#header_nav_main_mobile ul li.lvl-0 > a',function(e) {
            // only for direct taps on the link
            if(e.target === this) {
                e.preventDefault();
                let showme = $(this).parent();
                if($(showme).length > 0) {
                    if($(showme).hasClass('active')) {
                        $(showme).removeClass('active');
                    } else {
                        $('.lvl-0').removeClass('active');
                        $(showme).addClass('active');
                    }
                }
            }
        });

        // second level submenu
        $(document).on('click','#header_nav_main_mobile ul li.lvl-1.has-children > a',function(e) {
            // only for direct taps on the link
            if(e.target === this) {
                e.preventDefault();
                let showme = $(this).parent();
                if($(showme).length > 0) {
                    if($(showme).hasClass('active')) {
                        $(showme).removeClass('active');
                        close_second_level();
                    } else {
                        close_second_level();
                        $(showme).addClass('active');
                    }
                }
            }
        });

        $(document).on('click','.sub2-back',function(e) {
            e.preventDefault();
            close_second_level();
        });

        function close_second_level() {
            $('.lvl-1').removeClass('active');
            $('body').removeClass('menu-mobile-second-level');
        }

    }
}) (jQuery);;(function($) {

    $(window).scroll(function() {
        if($(window).scrollTop() > 200) {
            $('body').addClass('scrolltop-visible');
        } else {
            $('body').removeClass('scrolltop-visible');
        }
    });

    $(document).on('click','#scrolltop',function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

}) (jQuery);;(function() {
    'use strict';
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission
        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(form).trigger('mdFormValidationErrors')
                } else {
                    $(form).trigger('mdFormValidationSuccess')
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);
})();;(function($) {

    $('.testimonial').each(function(index) {
        let cite = $(this).find('cite').first();
        // need to remove whitespace before counting
        let chars = $(cite).text().trim().replace(/\s+/g,' ').length;
        if(chars > 500) {
            $(cite).addClass('citelength-huge');
        } else if(chars > 400) {
            $(cite).addClass('citelength-longer')
        } else if(chars > 300) {
            $(cite).addClass('citelength-long');
        } else if(chars > 200) {
            $(cite).addClass('citelength-medium')
        } else {
            $(cite).addClass('citelength-short');
        }
    });

}) (jQuery);;const ACCESSABILITY_ELEMENTS = {
  clickSelectors:  "div.cta,div.btn,a.cta,a.btn",
};

(function ($) {

    $(document).on("keydown", function (e) {
        if (e.keyCode == 13 || e.which == '13') {

           let curElem = $(document.activeElement);

           if($(curElem).is(ACCESSABILITY_ELEMENTS.clickSelectors)) {
               $(curElem).trigger('click');
           }
        }
    });

})(jQuery);;(function ($) {
    if(window.USE_CIS_COOKIE_NOTICE === true) {
        let value = "; " + document.cookie;
        let parts = value.split("; cis_gdpr_cookie_accept=");
        let show_notice = true;
        if (parts.length === 2) {
            let was_set = parts.pop().split(";").shift();
            if (was_set !== "1") {
                show_notice = false;
            }
        }

        if(show_notice) {
            $('#cis-gdpr-footer').show();

            $(document).on('click', '#cis-gdpr-footer-accept', function (e) {
                e.preventDefault();
                $('#cis-gdpr-footer').hide();
                let d = new Date();
                d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
                let expires = "expires=" + d.toUTCString();
                document.cookie = "cis_gdpr_cookie_accept=1;" + expires + ";path=/";
            });
        }
    }
})(jQuery);